<template>
  <b-table-simple
    responsive
    striped
    small
    outlined
    sticky-header="500px"
    table-class="stats-table"
  >
    <b-thead>
      <b-tr>
        <!-- Played Cell Header -->
        <b-th v-b-tooltip.v-secondary="{ title: 'Played In Game', boundary: 'window' }">
          <b-form-checkbox
            class="played-game"
            :checked="allPlayedInGame"
            @change="handleAllPlayedChange"
          />
        </b-th>

        <!-- Player Cell Header -->
        <b-th sticky-column>
          Player
        </b-th>

        <!-- Started Cell Header -->
        <!--              <b-th v-b-tooltip.v-secondary="{ title: 'Started', boundary: 'window' }">-->
        <!--                ST-->
        <!--              </b-th>-->

        <!-- Stat Cell Header -->
        <template v-for="statistic in filteredStatistics">
          <b-th
            :key="statistic.name"
            v-b-tooltip.v-secondary="{ title: statistic.name, boundary: 'window' }"
          >
            {{ statistic.abbreviation.toUpperCase() }}
          </b-th>
        </template>
      </b-tr>
    </b-thead>

    <b-tbody>
      <!-- No Players Found -->
      <b-tr
        v-if="!rosters.length"
        class="b-table-empty-row"
      >
        <b-td :colspan="filteredStatistics.length + 2">
          <div role="alert">
            <h6 class="text-center">
              No players found.
            </h6>
          </div>
        </b-td>
      </b-tr>

      <!-- Stat Rows -->
      <b-tr
        v-for="roster in rostersWithStats"
        v-else
        :key="roster.id"
      >
        <!-- Played Cell -->
        <b-td>
          <b-form-checkbox
            :checked="roster.stats['played']"
            @change="handlePlayedChange(roster, $event)"
          />
        </b-td>

        <!-- Player Cell -->
        <b-td
          sticky-column
          style="width: 100px; white-space: nowrap"
        >
          {{ roster.jersey_number }} {{ roster.player.full_name }}
        </b-td>

        <!-- Started Cell -->
        <!--              <b-td>-->
        <!--                <b-form-checkbox-->
        <!--                  button-->
        <!--                  button-variant="link"-->
        <!--                  class="started-game"-->
        <!--                  :checked="player.stats[category.name].games_started > 0"-->
        <!--                  :disabled="playerPlayedInCategory(player, category.name)"-->
        <!--                  @input="player.stats[category.name].games_started = +$event"-->
        <!--                >-->
        <!--                  {{ player.stats[category.name].games_started > 0 ? 'Yes' : 'No' }}-->
        <!--                </b-form-checkbox>-->
        <!--              </b-td>-->

        <!-- Stat Cell -->
        <template v-for="statistic in filteredStatistics">
          <b-td :key="statistic.name">
            <input-stat
              :value="roster.stats[statistic.key]"
              :disabled="roster.stats.played === false"
              @input="handleStatInput(roster.id, statistic, $event)"
            />
          </b-td>
        </template>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BThead, BTh, BTbody, BTd, BTr, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import InputStat from '@/components/forms/inputs/InputStat.vue'

export default {
  components: {
    BTableSimple,
    BThead,
    BTh,
    BTbody,
    BTd,
    BTr,
    BFormCheckbox,
    InputStat,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    rosters: {
      required: true,
      type: Array,
    },
    statistics: {
      required: true,
      type: Array,
    },
    stats: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      editStats: {},
    }
  },
  computed: {
    allPlayedInGame() {
      return this.stats.length && this.stats.filter(stats => stats.played).length === this.rosters.length
    },
    filteredStatistics() {
      return this.statistics.filter(statistic => !['Games Played', 'Games Started'].includes(statistic.name))
    },
    rostersWithStats() {
      return this.rosters.map(roster => {
        const stats = this.findStatsForRoster(roster.id) || {}

        // Check if player has any stats
        if (!Object.keys(stats).length) {
          // Add each statistic to the stats array.
          this.statistics.forEach(statistic => {
            stats[statistic.key] = 0
          })

          stats.played = false
        }

        // These are the stats that were initially there from the API.
        if (!Object.hasOwnProperty.call(stats, 'played')) {
          stats.played = true
        }

        return {
          ...roster,
          stats,
        }
      })
    },
  },
  methods: {
    findStatsForRoster(rosterId) {
      return this.stats.find(stats => stats.roster_id === rosterId)
    },
    handleStatInput(rosterId, statistic, stat) {
      // User is editing stats for a different same player.
      if (rosterId !== this.editStats?.roster_id) {
        this.editStats = this.findStatsForRoster(rosterId)
      }

      this.editStats[statistic.key] = stat
    },
    handleAllPlayedChange(played) {
      this.$emit('update:stats', this.rostersWithStats.map(roster => ({
        roster_id: roster.id,
        ...roster.stats,
        played,
      })))
    },
    handlePlayedChange(roster, played) {
      const stats = this.findStatsForRoster(roster.id)

      if (stats) {
        stats.played = played
      } else {
        this.stats.push({
          roster_id: roster.id,
          ...roster.stats,
          played,
        })
      }

      this.$emit('update:stats', [...this.stats])
    },
  },
}
</script>

<style lang="scss" scoped>
.played-game ::v-deep label {
  font-size: inherit;
}

.started-game ::v-deep .btn {
  padding: 0 .5rem
}
</style>
