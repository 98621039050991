<template>
  <component
    :is="component"
    type="number"
    inputmode="numeric"
    class="stat"
    v-bind="$attrs"
    :value="value"
    :min="$attrs['min'] || 0"
    @update="handleUpdate"
    @click="handleClick"
    @blur="handleBlur"
  />
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
import FormValidationField from '@/components/forms/validation/FormValidationField.vue'

export default {
  components: {
    BFormInput,
    FormValidationField,
  },
  props: {
    value: {
      default: null,
      validator(value) {
        if (!value) {
          return true
        }

        // Custom validator to check if the value is numeric.
        return !isNaN(parseFloat(value)) && isFinite(value)
      },
    },
    component: {
      type: String,
      default: 'b-form-input',
      validator(value) {
        return ['b-form-input', 'form-validation-field'].includes(value)
      },
    },
  },
  methods: {
    handleUpdate(value) {
      this.$emit('input', value ? parseFloat(value) : 0)
    },
    handleClick(event) {
      event.target.select()

      this.$emit('click', event)
    },
    handleBlur(event) {
      if (!this.value) {
        this.$emit('input', 0)
      }

      this.$emit('blur', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.stat {
  width: 50px;
  margin: auto;
  text-align: center;
}
</style>
