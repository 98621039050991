<template>
  <validation-form
    v-if="game"
    ref="validationForm"
    :submit-buttons="{ loading: form.isLoading, cancel: { text: 'Back' } }"
    @validated="submit"
    @cancel="$router.push({ name: 'event.schedule' })"
  >
    <b-card>
      <!-- Title -->
      <template #header>
        <div class="mb-2">
          <b-card-title class="font-medium-4">
            {{ game.teams.home.name }} vs {{ game.teams.visitor.name }}
          </b-card-title>

          <b-card-sub-title class="mt-25 text-black-50">
            {{ formattedGameDate }} at {{ formattedVenue }}
          </b-card-sub-title>
        </div>
      </template>

      <!-- Team Scores -->
      <b-card-title class="font-medium-2">
        Score
      </b-card-title>

      <scores-table
        :playing-periods="game.season.playing_periods ? game.season.playing_periods : undefined"
        :teams="game.teams"
        class="mb-4"
      />

      <!-- Stats Categories -->
      <b-tabs>
        <b-tab
          v-for="statsCategory in statsCategories"
          :key="statsCategory.name"
          :title="title(statsCategory.name)"
          :active="statsCategory.active"
        >
          <section
            v-for="(team, hv) in game.teams"
            :key="hv"
            :class="{ 'mb-2': hv === 'home' }"
          >
            <b-card-title class="font-medium-2">
              {{ team.name }} Stats
            </b-card-title>

            <stats-table
              :key="`${statsCategory.name}_${team.team_id}`"
              :rosters="team.roster"
              :statistics="statsCategory.statistics"
              :stats.sync="team.stats_categories.find(teamStatsCategory => teamStatsCategory.name === statsCategory.name).stats"
            />
          </section>
        </b-tab>
      </b-tabs>

    </b-card>
  </validation-form>
</template>

<script>
/* eslint-disable no-param-reassign */
import {
  BCard, BCardTitle, BCardSubTitle, BTabs, BTab,
} from 'bootstrap-vue'
import ValidationForm from '@/components/forms/validation/ValidationForm.vue'
import StatsTable from '@/views/events/manage/games/EventManageGameStatsTable.vue'
import ScoresTable from '@/views/events/manage/games/EventManageGameStatsScoresTable.vue'

import axios from '@/libs/axios'
import useFlash from '@/composables/useFlash'
import Form from '@/forms/Form'
import { formatGameDateAndTime, title } from '@core/utils/filter'

const { flashError } = useFlash()

export default {
  name: 'EventGameStats',
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BTabs,
    BTab,
    ValidationForm,
    StatsTable,
    ScoresTable,
  },
  data() {
    return {
      game: null,
      form: new Form({
        teams: [],
      }),
      statsCategories: null,
      fields: [
        'team',
        'score',
      ],
    }
  },
  beforeRouteEnter(to, from, next) {
    axios.get(`/games/${to.params.game}`)
      .then(response => {
        next(vm => {
          const { data, glossary } = response.data

          vm.game = data

          vm.statsCategories = glossary.stats_categories.map((statsCategory, index) => ({
            active: (index === 0),
            ...statsCategory,
          }))
        })
      })
      .catch(error => {
        flashError(error)
      })
  },
  computed: {
    formattedGameDate() {
      const { date, time } = formatGameDateAndTime(this.game.date, this.game.time)

      if (!this.game.date && !this.game.time) {
        return date
      }

      return `${date} ${time}`
    },
    formattedVenue() {
      if (!this.game.venue && !this.game.playing_surface) {
        return 'TBD'
      }

      const playingSurface = this.game.playing_surface ? this.game.playing_surface.name : 'TBD'

      return `${this.game.venue.name} ${playingSurface}`
    },
  },
  methods: {
    title,
    submit() {
      this.form.teams = Object.keys(this.game.teams).map(homeVisitor => {
        const team = this.game.teams[homeVisitor]
        const data = {
          home_visitor: homeVisitor,
          score: parseInt(team.score),
          line_score: team.line_score,
        }

        data.stats_categories = team.stats_categories.map(statsCategory => ({
          name: statsCategory.name,
          // Remove stats for players that did not play and also delete the played key in each stats array.
          stats: statsCategory.stats.filter(stats => stats.played).map(({ played, ...stats }) => stats),
        }))

        return data
      })

      this.form.post(`/games/${this.$route.params.game}/stats`)
    },
  },
}
</script>

<style lang="scss">
  .stats-table {
    th, td {
      text-align: center;
    }
  }
</style>
