<template>
  <b-table-simple
    responsive
    striped
    small
    outlined
    table-class="stats-table"
  >
    <b-thead>
      <b-tr>
        <b-th sticky-column>
          Team
        </b-th>
        <b-th
          v-for="period in playingPeriods"
          :key="period"
        >
          {{ period }}
        </b-th>
        <b-th>Final</b-th>
      </b-tr>
    </b-thead>

    <b-tbody>
      <b-tr
        v-for="team in teams"
        :key="team.team_id"
      >
        <!-- Name Cell -->
        <b-td sticky-column>
          {{ team.name }}
        </b-td>

        <!-- Period Cells -->
        <b-td
          v-for="(score, index) in team.line_score"
          :key="index"
        >
          <input-stat v-model="team.line_score[index]" />
        </b-td>

        <!-- Final Cell -->
        <b-td>
          <input-stat v-model="team.score" />
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BThead, BTh, BTbody, BTd, BTr,
} from 'bootstrap-vue'
import InputStat from '@/components/forms/inputs/InputStat.vue'

export default {
  components: {
    InputStat,
    BTableSimple,
    BThead,
    BTh,
    BTbody,
    BTd,
    BTr,
  },
  props: {
    playingPeriods: {
      type: Number,
      default: 4,
    },
    teams: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .table {
  width: auto;
  table-layout: auto; /* Use 'auto' for each column to adjust its width based on content */
}
</style>
